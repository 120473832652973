import React from 'react';
import { certificates } from '../data';
import Projects from './Projects';
import { cvPositions } from '../data';

const Certificates = () => {
  return (
    <section id='certificates' className='section bg-tertiary min-h-[300px]'>
      <div className='container mx-auto'>
        <div className='flex flex-col items-center text-center mt-12'>
          <h2 className='section-title before:content-certificates relative before:absolute before:opacity-40 before:-top-[2rem] before:-left-3/4 before:hidden before:lg:block'>
            My Certificates
          </h2>
          <div className='w-full text-left'>
            {certificates.map((category, index) => (
              <div key={index} className='mb-8'>
                <h3 className='text-2xl font-bold mt-8'>{category.category}</h3>
                <ul className='list-disc list-inside mt-2 pl-5'>
                  {category.items.map((item, idx) => (
                    <li key={idx} className='text-wrap'>{item}</li>
                  ))}
                </ul>
              </div>
            ))}
          </div>
        </div>
      </div>
    </section>
  );
};

export default Certificates;
