import React from 'react';

// import img
import Image from '../assets/img/me_privat.jpg';

const About = () => {
  return (
    <section className='section bg-secondary' id='about'>
      <div className='container mx-auto'>
        <div className='flex flex-col xl:flex-row gap-24'>
          <img
            className='object-cover h-full w-[450px] md:mx-auto lg:mx-0 rounded-2xl'
            src={Image}
            alt=''
          />
          <div className='flex flex-col items-center text-center lg:items-start lg:text-left'>
            <div className='flex flex-col'>
              <h2 className='text-3xl lg:text-4xl font-medium lg:font-extrabold mb-3 before:content-about relative before:absolute before:opacity-40 before:-top-[2rem] before:hidden before:lg:block'>
                Sebastian Ziemann
              </h2>
              <p className='mb-4 text-accent'>
              Solution Architect
              </p>
              <hr className='mb-8 opacity-5' />
              <p className='mb-8'>
                As a Technical Solution Architect, specializing in Microsoft Dynamics 365 CE and Azure, 
                I bring a strong foundation in designing and implementing scalable, cloud-based solutions, 
                that drive digital transformation for businesses. 
                With hands-on expertise in C# and TypeScript and a focus on customer engagement and cloud architecture, 
                I work closely with clients to deliver tailored solutions that enhance business agility and resilience. 
                <br />
                <br />
                My experience spans multiple industries with a particular emphasis on solutions that leverage Microsoft technologies to 
                optimize customer experiences and streamline operations. I’m passionate about harnessing the power of Dynamics 365 and Azure 
                to create integrated systems that align with organizational goals and accelerate growth.
                <br />
                <br />
                Currently based in Stuttgart, I am open to connect with like-minded 
                professionals and discussing transformative solutions within the Microsoft ecosystem
              </p>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

export default About;
