import React from 'react';
import Projects from './Projects';
import { cvPositions } from '../data';

const Cv = () => {
  const professionalExperience = cvPositions.filter(position => position.type === 'work');
  const education = cvPositions.filter(position => position.type === 'education');

  return (
    <section id='cv' className='section bg-primary min-h-[1400px]'>
      <div className='container mx-auto'>
        <div className='flex flex-col items-center text-center mt-12'>
          <h2 className='section-title before:content-cv relative before:absolute before:opacity-40 before:-top-[2rem] before:-left-3/4 before:hidden before:lg:block'>
            My CV
          </h2>
          <div className='w-full text-left'>
            <h3 className='text-2xl font-bold mt-8'>PROFESSIONAL EXPERIENCE</h3>
            {professionalExperience.map((position, index) => (
              <div key={index} className='mb-8'>
                <h3 className='text-xl font-semibold'>{position.company}</h3>
                <p className='text-lg'>{position.position}</p>
                <p className='text-sm text-gray-500'>{position.period}</p>
                <ul className='list-disc list-inside mt-2 pl-5'>
                  {position.responsibilities && position.responsibilities.map((responsibility, idx) => (
                    <li key={idx} className='text-wrap'>{responsibility}</li>
                  ))}
                </ul>
                {position.details && (
                  <ul className='list-disc list-inside mt-2 pl-5'>
                    {position.details.map((detail, idx) => (
                      <li key={idx} className='text-wrap'>{detail}</li>
                    ))}
                  </ul>
                )}
              </div>
            ))}
            <h3 className='text-2xl font-bold mt-8'>EDUCATION</h3>
            {education.map((position, index) => (
              <div key={index} className='mb-8'>
              <h3 className='text-xl font-semibold'>{position.company}</h3>
              <p className='text-lg'>{position.position}</p>
              <p className='text-sm text-gray-500'>{position.period}</p>
              <ul className='list-disc list-inside mt-2 pl-5'>
                {position.responsibilities && position.responsibilities.map((responsibility, idx) => (
                  <li key={idx} className='text-wrap'>{responsibility}</li>
                ))}
              </ul>
              {position.details && (
                <ul className='list-disc list-inside mt-2 pl-5'>
                  {position.details.map((detail, idx) => (
                    <li key={idx} className='text-wrap'>{detail}</li>
                  ))}
                </ul>
              )}
            </div>
            ))}
          </div>
        </div>
      </div>
    </section>
  );
};

export default Cv;
