import React from 'react';

// import contact data
import { contact } from '../data';


const Contact = () => {
  return (
    <section className='section bg-secondary' id='contact'>
      <div className='container mx-auto'>
        <div className='flex flex-col items-center text-center'>
          <h2 className='section-title before:content-contact relative before:absolute before:opacity-40 before:-top-7 before:-left-40 before:hidden before:lg:block'>
            Contact me
          </h2>
          <p className='subtitle'>
            If you have any questions or would like to get in touch, please feel free to reach out. I am always open to discussing new projects, creative ideas, or opportunities to be part of your visions.
          </p>
        </div>
          <div className='flex flex-1 flex-row items-center justify-center space-x-4 mb-4 lg:mb-0 lg:pt-1'>
            {contact.map((item, index) => {
              const { icon, title, subtitle, description } = item;
              return (
                <div className='flex flex-col lg:flex-col gap-x-4' key={index}>
                  <div className='text-accent rounded-sm w-14 h-14 flex items-center justify-center mt-2 mb-2 lg:mb-0 text-2xl'>
                    {icon}
                  </div>
                  <div>
                    <h4 className='font-body text-xl mb-1'>{title}</h4>
                    <p className='mb-1 text-paragraph'>{subtitle}</p>
                    <p className='text-accent font-normal '>{description}</p>
                  </div>
                </div>
              );
            })}
          </div>
        </div>
    </section>
  );
};

export default Contact;
